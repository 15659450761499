<template>
	<div class="flex justify-center mb-1">
		<template v-if="subSkillLevel === 'Grundkenntnisse'">
			<HokIcon
				name="icon:circle-filled"
				:size="3"
				:color="
					backgroundColor === 'bg-color-moss' || backgroundColor === 'bg-color-midnight-green'
						? 'white'
						: 'blue-grey'
				"
				class="mx-1"
			/>
			<HokIcon
				v-for="n in emptyCircles"
				:key="'empty' + n"
				name="icon:circle"
				:color="
					backgroundColor === 'bg-color-moss' || backgroundColor === 'bg-color-midnight-green'
						? 'white'
						: 'blue-grey'
				"
				:size="3"
				class="mx-1"
			/>
		</template>
		<template v-else-if="subSkillLevel === 'Fortgeschritten'">
			<HokIcon
				v-for="n in filledCircles"
				:key="'filled' + n"
				name="icon:circle-filled"
				:color="
					backgroundColor === 'bg-color-moss' || backgroundColor === 'bg-color-midnight-green'
						? 'white'
						: 'blue-grey'
				"
				:size="3"
				class="mx-1"
			/>
			<HokIcon
				v-for="n in emptyCircles"
				:key="'empty' + n"
				name="icon:circle"
				:color="
					backgroundColor === 'bg-color-moss' || backgroundColor === 'bg-color-midnight-green'
						? 'white'
						: 'blue-grey'
				"
				:size="3"
				class="mx-1"
			/>
		</template>
		<template v-else-if="subSkillLevel === 'Fließend'">
			<HokIcon
				v-for="n in filledCircles"
				:key="'filled' + n"
				name="icon:circle-filled"
				:color="
					backgroundColor === 'bg-color-moss' || backgroundColor === 'bg-color-midnight-green'
						? 'white'
						: 'blue-grey'
				"
				:size="3"
				class="mx-1"
			/>
			<HokIcon
				v-for="n in emptyCircles"
				:key="'empty' + n"
				name="icon:circle"
				:color="
					backgroundColor === 'bg-color-moss' || backgroundColor === 'bg-color-midnight-green'
						? 'white'
						: 'blue-grey'
				"
				:size="3"
				class="mx-1"
			/>
		</template>
		<template v-else-if="subSkillLevel === 'Muttersprache'">
			<HokIcon
				v-for="n in filledCircles"
				:key="n"
				name="icon:circle-filled"
				:color="
					backgroundColor === 'bg-color-moss' || backgroundColor === 'bg-color-midnight-green'
						? 'white'
						: 'blue-grey'
				"
				:size="3"
				class="mx-1"
			/>
		</template>
	</div>
</template>
<script lang="ts">
import HokIcon from '@hokify/shared-components-nuxt3/lib/components/HokIcon.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'CvSubSkillLevelElement',
	components: { HokIcon },
	data() {
		return {
			sumOfCirlces: 4
		};
	},
	computed: {
		filledCircles(): number {
			let circles: number;

			switch (this.subSkillLevel) {
				case 'Grundkenntnisse':
					circles = 1;
					break;
				case 'Fortgeschritten':
					circles = 2;
					break;
				case 'Fließend':
					circles = 3;
					break;
				case 'Muttersprache':
				default:
					circles = 4;
					break;
			}
			return circles;
		},
		emptyCircles() {
			return this.sumOfCirlces - this.filledCircles;
		}
	},
	props: {
		subSkillLevel: { type: String },
		backgroundColor: { type: String, required: true }
	}
});
</script>
