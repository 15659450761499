<template>
	<div class="outer-spacing">
		<div class="no-break">
			<!-- heading + edit button-->
			<div
				:class="[
					{ 'flex justify-between': $slots.edit },
					{ ' items-start': profileOrMatchOverview }
				]"
			>
				<h2
					v-if="styling === 'long-list'"
					class="mb-3 color-inherit border-b border-color-grey-light"
				>
					{{ userSkillsObj.title }}
				</h2>
				<h2 v-else-if="styling === 'border-top-bottom'" class="mb-3 color-inherit">
					{{ userSkillsObj.title }}:
				</h2>
				<h2 v-else-if="styling === 'bar-left'" class="mb-3 color-inherit text-3xl font-thin">
					{{ userSkillsObj.title !== 'Sprachen' ? `${userSkillsObj.title}:` : '' }}
				</h2>
				<h2
					v-else-if="styling === 'active-sourcing'"
					class="mb-5 pb-2 text-lg font-bold border-b border-color-text"
				>
					{{ userSkillsObj.title }}
				</h2>
				<h4 v-else-if="profileOrMatchOverview" class="mb-5">
					{{ userSkillsObj.title }}
				</h4>
				<h2
					v-else
					:class="{ 'inline-block': !viewOnly }"
					class="mb-3 border-b-2 border-color-text uppercase sm:inline-block print:inline-block"
				>
					{{ userSkillsObj.title }}
				</h2>
				<slot name="edit" />
			</div>

			<!-- show skills -->
			<!-- default styling -->

			<TransitionGroup
				v-if="
					(styling === 'viewOnly' || styling === 'active-sourcing') &&
					userSkillsObj &&
					userSkillsObj.items &&
					userSkillsObj.items.length > 0
				"
				name="flip-list"
				tag="div"
			>
				<div v-if="profileOrMatchOverview" :key="'viewOnly'" class="w-11/12">
					<ul
						v-if="!showMore"
						class="flex flex-wrap"
						:class="{ 'mb-6': sortedItems && sortedItems.length >= 8 }"
					>
						<template v-if="sortedItems">
							<li
								v-for="subSkill in sortedItems.slice(0, 8)"
								:key="subSkill.name"
								:class="[{ 'cursor-pointer': !viewOnly }]"
								class="border border-color-grey-light rounded-full py-1 px-3 mr-2 mb-2 text-sm list-none"
								@click="$emit('edit-cv-skill')"
							>
								{{ subSkill.name }}
								<span
									v-if="
										predefinedSkillsObj &&
										predefinedSkillsObj.levels &&
										predefinedSkillsObj.levels.length &&
										subSkill &&
										subSkill.level
									"
								>
									{{
										predefinedSkillsObj.levels[subSkill.level]
											? `- ${predefinedSkillsObj.levels[subSkill.level]}`
											: ''
									}}
								</span>
							</li>
						</template>
					</ul>
					<ul v-else class="flex flex-wrap mb-6">
						<li
							v-for="(subSkill, index) in sortedItems"
							:key="index"
							:class="[{ 'cursor-pointer': !viewOnly }]"
							class="border border-color-grey-light rounded-full py-1 px-3 mr-2 mb-2 text-sm list-none"
							@click="$emit('edit-cv-skill')"
						>
							{{ subSkill.name }}
							<span
								v-if="
									predefinedSkillsObj &&
									predefinedSkillsObj.levels &&
									predefinedSkillsObj.levels.length &&
									subSkill &&
									subSkill.level
								"
							>
								{{
									predefinedSkillsObj.levels[subSkill.level]
										? `- ${predefinedSkillsObj.levels[subSkill.level]}`
										: ''
								}}
							</span>
						</li>
					</ul>
					<div
						v-if="sortedItems && sortedItems.length > 9"
						:key="'showMore'"
						class="w-full flex flex-col justify-center items-center cursor-pointer"
						@click="toggleShowMore"
					>
						<p v-if="!showMore" class="mb-1">Mehr anzeigen</p>
						<p v-else class="mb-1">Weniger anzeigen</p>
						<HokIcon
							ref="arrow-down"
							name="icon:arrow-down"
							:class="{ 'rotate-180 ': showMore }"
							class="transition-transform duration-3000 bg-transparent"
						/>
					</div>
				</div>
				<ul v-else :key="'notViewOnly'" class="flex flex-wrap">
					<li
						v-for="(subSkill, index) in sortedItems"
						:key="index"
						:class="{ 'cursor-pointer': !viewOnly }"
						class="border rounded-full py-1 px-3 mr-2 mb-2 text-sm"
						@click="$emit('edit-cv-skill')"
					>
						{{ subSkill.name }}
						<span
							v-if="
								predefinedSkillsObj &&
								predefinedSkillsObj.levels &&
								predefinedSkillsObj.levels.length &&
								subSkill &&
								subSkill.level
							"
						>
							{{
								predefinedSkillsObj.levels[subSkill.level]
									? `- ${predefinedSkillsObj.levels[subSkill.level]}`
									: ''
							}}
						</span>
					</li>
				</ul>
			</TransitionGroup>

			<!-- styling V2 -->
			<TransitionGroup
				v-else-if="
					styling === 'v2' && userSkillsObj && userSkillsObj.items && userSkillsObj.items.length > 0
				"
				class="list-disc list-inside"
				name="flip-list"
				tag="ul"
			>
				<li
					v-for="(subSkill, index) in sortedItems"
					:key="index"
					:class="{ 'cursor-pointer': !viewOnly }"
					class="mr-2 mb-2 text-sm"
					@click="$emit('edit-cv-skill')"
				>
					{{ subSkill.name }}
					<span
						v-if="
							predefinedSkillsObj &&
							predefinedSkillsObj.levels &&
							predefinedSkillsObj.levels.length &&
							subSkill &&
							subSkill.level
						"
					>
						{{
							predefinedSkillsObj.levels[subSkill.level]
								? `- ${predefinedSkillsObj.levels[subSkill.level]}`
								: ''
						}}
					</span>
				</li>
			</TransitionGroup>

			<!-- long-list || border-top-bottom -->
			<ul
				v-else-if="
					(styling === 'long-list' || styling === 'border-top-bottom') &&
					userSkillsObj &&
					userSkillsObj.items &&
					userSkillsObj.items.length
				"
				class="list-disc list-inside"
				:class="lineSpacing"
			>
				<li
					v-for="(subSkill, index) in sortedItems"
					:key="index"
					:class="[lineSpacing, { 'cursor-pointer': !viewOnly }]"
					class="text-sm"
					@click="$emit('edit-cv-skill')"
				>
					<strong>{{ subSkill.name }}</strong
					><span
						v-if="
							predefinedSkillsObj &&
							predefinedSkillsObj.levels &&
							predefinedSkillsObj.levels.length &&
							subSkill &&
							subSkill.level
						"
						>{{
							predefinedSkillsObj.levels[subSkill.level]
								? `, ${predefinedSkillsObj.levels[subSkill.level]}`
								: ''
						}}
					</span>
				</li>
			</ul>
			<!-- bar-left-->
			<div
				v-else-if="
					styling === 'bar-left' &&
					userSkillsObj &&
					userSkillsObj.items &&
					userSkillsObj.items.length
				"
				class="list-disc list-inside"
			>
				<ul v-if="userSkillsObj.title !== 'Sprachen'" class="list-disc list-inside ml-4">
					<li
						v-for="(subSkill, index) in sortedItems"
						:key="index"
						:class="[lineSpacing, { 'cursor-pointer': !viewOnly }]"
						class="text-sm"
						@click="$emit('edit-cv-skill')"
					>
						<strong>{{ subSkill.name }}</strong
						><span
							v-if="
								predefinedSkillsObj &&
								predefinedSkillsObj.levels &&
								predefinedSkillsObj.levels.length &&
								subSkill &&
								subSkill.level
							"
							>{{
								predefinedSkillsObj.levels[subSkill.level]
									? `, ${predefinedSkillsObj.levels[subSkill.level]}`
									: ''
							}}
						</span>
					</li>
				</ul>
			</div>

			<!-- empty -->
			<div
				v-else-if="profileOrMatchOverview && userSkillsObj && !viewOnly"
				class="text-color-grey-medium"
				@click="$emit('edit-cv-skill')"
			>
				<p
					v-if="
						userSkillsObj && userSkillsObj.category && userSkillsObj.category.type === 'language'
					"
					class="mb-0"
				>
					Welche Sprachen sprichst du?
				</p>
				<p
					v-else-if="
						userSkillsObj && userSkillsObj.category && userSkillsObj.category.type === 'edv-and-it'
					"
					class="mb-0"
				>
					Welche Programme oder Skills beherrschst du?
				</p>
				<p
					v-else-if="
						userSkillsObj &&
						userSkillsObj.category &&
						userSkillsObj.category.type === 'drivinglicence'
					"
					class="mb-0"
				>
					Welche Führerscheine besitzt du?
				</p>
				<p
					v-else-if="
						userSkillsObj && userSkillsObj.category && userSkillsObj.category.type === 'hobby'
					"
					class="mb-6"
				>
					Welche Hobbies hast du?
				</p>
			</div>
			<p v-else class="text-color-grey-medium">Keine {{ userSkillsObj.title }} angegeben.</p>
		</div>
	</div>
</template>

<script lang="ts">
import type { IAPIUserCvSkill, IAPICvSkill, IAPIUserCvSkillCategory } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import CvSubSkillLevelElement from './CvSubSkillLevelElement.vue';

export default defineComponent({
	name: 'CvSkillElement',
	components: { CvSubSkillLevelElement },
	data() {
		return {
			showMore: false
		};
	},
	computed: {
		sortedItems() {
			return this.userSkillsObj?.items?.slice(0).sort(this.sortByName);
		}
	},
	methods: {
		sortByName(a, b) {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		},
		toggleShowMore() {
			this.showMore = !this.showMore;
		}
	},
	props: {
		styling: {
			type: String,
			default: 'viewOnly',
			validator: (value: string) =>
				[
					'viewOnly',
					'v2',
					'long-list',
					'border-top-bottom',
					'bar-left',
					'bar-left-languages',
					'active-sourcing'
				].includes(value)
		},
		lineSpacing: { type: String, default: 'leading-normal' },
		userSkillsObj: {
			type: Object as PropType<IAPIUserCvSkill>,
			default: () => ({
				/* empty object */
			})
		},
		predefinedSkillsObj: {
			type: Object as PropType<IAPICvSkill | IAPIUserCvSkillCategory>,
			default: () => ({
				/* empty object */
			})
		},
		viewOnly: { type: Boolean, default: false },
		profileOrMatchOverview: { type: Boolean, default: false }
	}
});
</script>

<style lang="scss" scoped>
.outer-spacing {
	margin-bottom: 2rem;
	&:last-child {
		margin-bottom: 0;
	}
}
.flip-list-move {
	transition: transform 360ms;
}

.flip-list-enter-active,
.flip-list-leave-active {
	transition: all 360ms;
}
.flip-list-enter,
.flip-list-leave-to {
	opacity: 0;
	transform: translateY(30px);
}
</style>
